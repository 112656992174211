<template>
  <div>
    <v-app-bar
      :app="isPrivacyPolicy"
      :fixed="isScrolling"
      :absolute="!isScrolling"
      :height="isScrolling ? '84px' : null"
      flat
      tile
      :color="isScrolling ? 'white' : 'transparent'"
      v-scroll="onScroll"
      :dark="!isScrolling"
      :class="navbarMarginClass"
    >
      <v-container class="py-0 fill-height container-wrapper">
        <v-row no-gutters :align="isScrolling ? 'center' : 'start'">
          <router-link to="/">
            <v-img
              v-if="isScrolling"
              contain
              height="50px"
              width="90px"
              alt="Logo"
              src="@/assets/img/logo/logo_dark_text2.svg"
              transition="scale-transition"
            />
            <v-img
              :height="$vuetify.breakpoint.mobile ? '72px' : null"
              v-else
              alt="Logo"
              src="@/assets/img/logo/logo.svg"
              transition="scale-transition"
              contain
            />
          </router-link>
          <v-spacer/>

          <template v-if="!this.isHome || !$vuetify.breakpoint.mobile">

            <v-list
              flat
              :height="isScrolling ? '91px' : null"
              color="transparent"
              class="v-list--gap d-flex"
              v-if="showNavBarList"
            >
              <v-list-item
                v-for="(item, i) in navbarList"
                :key="i"
                :ripple="false"
                :to="{name: item.to, hash: item.hash}"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.title"
                    style="font-size: 16px;line-height: normal"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list
              v-else-if="!this.isHome"
              flat
              color="transparent"
              class="v-list--gap d-flex"
            >
              <v-btn
                to="/"
                outlined
                depressed
                :x-small="$vuetify.breakpoint.width <355"
                :ripple="false"
                :light="$vuetify.breakpoint.xlOnly"
                :class="{'light-btn': $vuetify.breakpoint.xlOnly}"
                style="font-size:16px;font-weight:normal;letter-spacing: normal;"
                class="mr-0">
                Back to home
              </v-btn>
            </v-list>

            <v-spacer v-if="isHome"/>
            <v-list v-if="isHome" class="py-1">
              <v-hover v-slot="{ hover }">
                <v-btn
                  :to="{name: 'Contact'}"
                  :color="isScrolling ? (hover ? 'error' : '#231a13') : null"
                  :dark="isScrolling"
                  :light="!isScrolling"
                  :ripple="false"
                  depressed
                  style="font-size:16px;font-weight:normal;letter-spacing: normal;"
                  class="mr-0">
                  Free consultation
                </v-btn>
              </v-hover>
            </v-list>

          </template>

          <v-app-bar-nav-icon
            v-if="this.isHome"
            v-ripple="false"
            class="hidden-xl-only"
            @click.stop="drawer = !drawer"
          />
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      fixed
    >
      <v-list
        nav
        dense
      >
        <v-list-item
          v-for="(item, i) in navbarList"
          :key="i"
          :ripple="false"
          :to="{name: item.to, hash: item.hash}"
        >
          <v-list-item-title
            v-text="item.title"
            style="font-size: 16px;line-height: normal;"
          />
        </v-list-item>
        <v-list-item :to="{name: 'Contact'}">
          <v-list-item-title style="font-size: 16px;line-height: normal;">
            Free consultation
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>

export default {
  name: 'AppNavBar',
  props: {
    isHome: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onScroll() {
      this.isScrolling = this.$route.name === 'PrivacyPolicy' || (this.isHome && (window.pageYOffset || document.documentElement.scrollTop || 0) > (this.$vuetify.breakpoint.mdAndUp
        ? 40
        : 32));
    },
  },
  computed: {
    isPrivacyPolicy() {
      return this.$route.name === 'PrivacyPolicy';
    },
    navbarMarginClass() {
      return {
        'mt-8': !this.isScrolling,
        'mt-md-10': !this.isScrolling,
        'v-app-bar--is-sticky': this.isScrolling,
      };
    },
    showNavBarList() {
      return this.isHome && this.$vuetify.breakpoint.smAndUp;
    },
    navbarList() {
      if (!this.isHome) {
        return this.contactItemList;
      }
      return this.itemList;
    },
  },
  data() {
    return {
      drawer: false,
      isScrolling: this.$route.name === 'PrivacyPolicy',
      itemList: [
        {
          title: 'Services',
          to: 'Home',
          hash: '#services',
        },
        {
          title: 'About us',
          to: 'Home',
          hash: '#about-us',
        },
        {
          title: 'Our values',
          to: 'Home',
          hash: '#our-values',
        },
        {
          title: 'Contact',
          to: 'Contact',
        },
      ],
      contactItemList: [
        {
          title: 'Back to home',
          to: 'Home',
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-app-bar--is-sticky {
  .v-toolbar__content {
    border-bottom: 1px solid #e8e9eb;
  }

  .v-list-item {
    opacity: 0.5;

    &:hover {
      opacity: 1
    }

    &--active {
      opacity: 1;
    }
  }
}

::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0 !important;
}
/*.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {*/
/*  opacity: 0;*/
/*}*/
::v-deep .v-list .v-list-item--link:before {
  background-color: #ffffff !important;
}

.v-list--gap .v-list-item {
  margin-left: 8px;
  margin-right: 8px;
  /*padding-left: 48px;*/
  /*padding-right: 48px;*/
}

.v-tabs--gap .v-tab {
  margin-left: 8px;
  margin-right: 8px;
  /*padding-left: 48px;*/
  /*padding-right: 48px;*/
}
::v-deep .v-app-bar--is-scrolled .v-list-item--active {
  border-bottom: 1px solid red
}
.light-btn.v-btn--outlined {
  border: thin solid #e8e9eb;
}
</style>
