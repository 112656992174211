<template>
  <div class="hero">
    <v-container
      fill-height
      class="container-wrapper"
    >
      <v-row
        class="white--text mt-15 mt-md-0 align-self-end align-self-md-center mb-11 mb-md-0"
      >
        <v-col cols="12">
          <v-flex v-if="!custom" class="flex-column">
            <v-row no-gutters>
              <div class="text-h2 text-md-h1 text-md-h1 white--text" style="max-width:585px">
                Fintech consultancy driving sustainable business growth
              </div>
              <div class="hero-subtitle text-body-2 text-md-body-1 white--text mt-4 mt-md-6">
                Devonshire Associates is a team of fintech industry experts dedicated to finding solutions and data-driven insights for your marketing, management, and business development needs.
              </div>
            </v-row>
            <v-row v-if="!$vuetify.breakpoint.mobile" no-gutters>
              <v-btn-arrow
                class="mt-12 rounded-0"
                x-large
                :ripple="false"
                color="error"
                to="contact"
                title="Get in touch"
                subtitle="Need consultation?"
              />
            </v-row>
          </v-flex>
          <v-flex class="flex-column" v-else>
            <v-row no-gutters>
              <div class="text-h2 text-md-h1 text-md-h1 white--text" style="max-width:585px">
                {{ title }}
              </div>
            </v-row>
          </v-flex>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* Local modules */
import VBtnArrow from '@/components/VBtnArrow.vue';

export default {
  name: 'Default',
  components: {
    VBtnArrow,
  },
  props: {
    custom: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
//@import '~vuetify/src/styles/styles.sass';

.hero {
  //background: url('../assets/img/header.png') center;
  background-image: linear-gradient(58deg, #231a13, 0%, rgba(35, 26, 19, 0.52) 101%), url('../assets/img/hero_bg.jpg');
  //background-position: center; /* Center the image */
  //background: url('../assets/img/header.png') center;
  background-size: cover;
  height: 512px;

  @media #{map-get($display-breakpoints, 'md-and-up')}{
    height: 930px;
  }
  /*object-fit: contain;*/

  //height:100vh;
}

.hero-subtitle {
  max-width: 640px;
}
</style>
