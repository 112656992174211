import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      layout: 'Blank',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: {
      layout: 'Blank',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

/**
 * scrollBehavior
 * - only available in html5 history mode
 * - defaults to no scroll behavior
 * - return false to prevent scroll
 *
 * @param to
 * @param from
 * @param savedPosition
 * @return {Promise<unknown>|boolean|*}
 */
const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition;
  }
  const position = {
    x: 0,
    y: 0,
  };

  // Handle params when we don't want scroll behavior and url change
  if (to.params.scroll === false) {
    return false;
  }

  // scroll to anchor by returning the selector
  if (to.hash) {
    position.selector = to.hash;
    position.behavior = 'smooth';

    // bypass #1number check
    if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
      position.offset = {
        x: 0,
        y: 84,
      };
      return position;
    }

    return new Promise((resolve) => {
      // @todo replace custom event with vue life cycle event
      this.app.$root.$once('triggerScroll', () => {
        Vue.nextTick(() => {
          position.offset = {
            x: 0,
            y: 0,
          };
          resolve(position);
        });
      });
    });

    // if the returned position is falsy or an empty object, will retain current scroll position.
    // return false;
  }

  return position;
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
});

export default router;
