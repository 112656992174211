<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="v-btn-arrow"
    :class="{'v-btn-arrow--active': hover, 'v-btn-multiline': !!subtitle}"
    @mouseover="hover = true"
    @mouseout="hover= false"
    style="transition: padding 0.1s ease-in-out;"
    tile
    depressed
  >
    <slot>
      <v-col>
        <div class="text-button-3">{{ subtitle }}</div>
        <div
          class="text-button-2 text-xl-button"
          :style="{'font-size': customTitleFontSize}"
          :class="{'mt-1': !!subtitle}">{{ title }}
        </div>
      </v-col>
      <v-col v-if="arrow">
        <v-img
          v-if="!arrowLong"
          :style="{'margin-left': $vuetify.breakpoint.mobile ? null : '96px'}"
          class="float-end"
          :width="$vuetify.breakpoint.mobile ? '40px' : '48px'"
          :height="$vuetify.breakpoint.mobile ? '40px' : '48px'"
          src="@/assets/img/arrow-right.svg"></v-img>
        <v-img
          v-else
          :style="{'margin-left': $vuetify.breakpoint.mobile ? null : '96px'}"
          class="float-end"
          width="32px"
          height="24px"
          src="@/assets/img/arrow-right-long.svg"></v-img>
      </v-col>
    </slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    customTitleFontSize: {
      type: String,
      default: null,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    arrowLong: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
<style lang="scss">
.v-btn.v-btn-arrow {
  padding-left: 8px !important;
  padding-right: 8px !important;

  &--active {
    padding-left: 12px !important;
    padding-right: 4px !important;
  }

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-left: 20px !important;
    padding-right: 20px !important;

    &--active {
      padding-left: 24px !important;
      padding-right: 16px !important;
    }
  }

  .v-btn__content {
    text-align: left;
    flex-direction: row !important
  }
}

.v-btn.v-btn-multiline {
  .v-btn__content {
    flex-direction: column;
    align-items: start;
  }
}
</style>
