<template>
  <div>
    <v-container class="py-0 container-wrapper">
      <v-row
        no-gutters
        style="width:100%"
      >
        <v-col cols="6">
          <v-row>
            <v-col>
              <span class="vertical-line"></span>
            </v-col>
            <v-col class="d-none d-xl-block ml-n9">
              <span class="vertical-line"></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col>
              <span class="vertical-line"></span>
            </v-col>
            <v-col class="d-none d-xl-block" style="text-align:center;">
              <span class="vertical-line"></span>
            </v-col>
            <v-col style="text-align:right">
              <span class="vertical-line"></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <Hero/>
    <v-container v-if="$vuetify.breakpoint.mobile" class="py-0">
      <v-btn-arrow
        class="rounded-0 mt-n8"
        large
        :ripple="false"
        color="error"
        to="contact"
        block
        title="Get in touch"
        subtitle="Need consultation?"
      />
    </v-container>

    <div :style="{'background-color': $vuetify.breakpoint.lgAndDown ? null : '#e8e9eb'}">
      <v-container
        :class="{'pt-0': $vuetify.breakpoint.mobile}"
        class="px-0">
        <div id="services" class="section-hash container-neg">
          <v-card class="card-service" flat>
            <v-container class="card-xl-px56">
              <v-row>
                <v-col cols="12" md="9">
                  <div class="text-h2 text-lg-h1 mt-2">Achieve high-impact results</div>
                  <div class="mt-3 mt-lg-6 text-body-2 text-lg-body-1" style="opacity:0.75">Our diverse fintech consultancy focuses on the unique attributes of your business to enhance, expand, and assist as you build into an industry leader. We leverage our years of experience to benefit everything from business processes to client relationships.</div>
                </v-col>
              </v-row>
              <v-row no-gutters class="pb-xl-11 mt-9 mt-xl-12">
                <v-col
                  cols="12"
                  lg="6"
                  v-for="(item, i) in serviceList"
                  :key="i"
                  class="px-0 mb-14"
                >

                  <v-card
                    class="mx-auto mb-2 service-card fill-height"
                    color="transparent"
                    tile
                    flat
                  >
                    <div class="line ml-0 d-none d-xl-block" style="margin-right:30px;"></div>
                    <v-card-text style="max-width:480px;" class="">
                      <v-img
                        :src="getImgUrl(item.img)"
                        contain
                        height="32px"
                        width="32px"
                      />
                      <p class="text-h4 text-lg-h3 text--primary mt-6" style="font-size:24px;margin-bottom:8px">
                        {{ item.title }}
                      </p>
                      <div
                        class="text--primary text-body-2"
                        style="opacity:0.75"
                        v-html="item.content"></div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
      </v-container>
    </div>
    <div class="section-top"></div>
    <div style="background-color: #e8e9eb;">
      <div
        class="section-about-us section-hash"
        id="about-us"
      >
        <v-container class="container-wrapper">
          <v-row class="mt-n6 flex-column-reverse flex-xl-row">
            <v-col cols="12" xl="6">
              <v-row no-gutters class="mt-10">
                <div class="text-h2 text-lg-h1">A mature approach to the fintech industry</div>
                <div class="mt-3 mt-lg-6 text-body-1" style="opacity:0.75">How do we know we can help? To put it simply, we’ve been there before. Our team of expert fintech consultants has had to face the same challenges and long odds to develop a robust business model that can survive in today’s marketplace. Our experience includes:</div>
              </v-row>
              <v-row class="mt-13" style="row-gap:6px">
                <v-col
                  cols="6">
                  <div class="line" style="margin-right:24px; background-color:#231a13"></div>
                  <div class="text-h2 text-lg-h1 error--text">12</div>
                  <div class="text-body-2 text-lg-body-1 mt-2" style="opacity:0.75">Years on the market</div>
                </v-col>
                <v-col
                  class="pl-0"
                  cols="6">
                  <div class="line" style="margin-right:24px; background-color:#231a13"></div>
                  <div class="text-h2 text-lg-h1 error--text">5</div>
                  <div class="text-body-2 text-lg-body-1 mt-2" style="opacity:0.75">Experts on board</div>
                </v-col>
                <v-col
                  cols="6">
                  <div class="line" style="margin-right:24px; background-color:#231a13"></div>
                  <div class="text-h2 text-lg-h1 error--text">100%</div>
                  <div class="text-body-2 text-lg-body-1 mt-2" style="opacity:0.75">Happy clients</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              class="pb-0"
            >
              <v-img
                class="float-xl-end"
                :max-width="$vuetify.breakpoint.mdAndDown ? '300px' : '486px'"
                :max-height="$vuetify.breakpoint.mdAndDown ? '360px' : '620px'"
                src="@/assets/img/person.png"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div
      class="section-our-values section-hash"
      id="our-values">
      <v-container
        class="container-values pt-0">
        <v-card class="pt-7 pt-xl-14" flat>
          <v-container class="card-xl-px56">
            <v-row>
              <v-col cols="12" md="9">
                <div class="text-h2 text-lg-h1 mt-2">We are driven by our values</div>
                <div class="mt-3 mt-lg-6 text-body-2 text-lg-body-1" style="opacity:0.75">We are a proud consulting agency with clients ranging from local SMEs to international interests.
                  That is why we place a high value on our core values to provide the best service possible.
                </div>
              </v-col>
            </v-row>
            <v-row class="pb-lg-16 mt-9">
              <v-col
                cols="12"
                sm="6"
                lg="3"
                v-for="(item, i) in valueList"
                :key="i"
                class="px-0"
              >
                <v-card
                  class="mx-auto service-card"
                  color="transparent"
                  tile
                  flat
                  height="100%"
                >
                  <v-card-text class="pb-0">
                    <div class="error" style="width:48px">
                      <v-img
                        :src="getImgUrl(item.img)"
                        contain
                        height="32px"
                        width="32px"
                        dark
                        class="mx-auto"
                        style="height:48px;"
                      />
                    </div>

                    <p class="text-h4 text-lg-h3 text--primary mt-8 mb-2">
                      {{ item.title }}
                    </p>
                    <div
                      class="text--primary text-body-2"
                      style="opacity:0.75"
                      v-html="item.content"></div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </div>
    <div
      class="section challenge-section"
    >
      <v-container class="container-wrapper pb-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            xl="5"
            class="pb-0"
          >
            <v-img
              height="100%"
              max-width="100%"
              :max-height="$vuetify.breakpoint.mobile ? '420px' : '620px'"
              src="@/assets/img/lets-talk-about-your-challenge-img.png"></v-img>
          </v-col>
          <v-col
            cols="12"
            xl="5"
            offset-xl="1"
            class="pb-0"
          >
            <v-row no-gutters class="mt-10 mt-lg-6">
              <div class="text-h2 text-lg-h1 white--text">Let's talk about your challenge</div>
              <div class="mt-3 mt-lg-6 text-body-2 text-lg-body-1 white--text" style="opacity:0.75">Reach out to our team today to schedule a consultation so we may better understand the unique needs and background of your fintech company. Our expert consultants are ready to offer all the advice, management, marketing, and insights you need to create sustainable business growth.</div>
            </v-row>
            <v-row class="mt-12" no-gutters>
              <v-btn-arrow
                :to="{name: 'Contact'}"
                :large="$vuetify.breakpoint.mobile"
                :x-large="!$vuetify.breakpoint.mobile"
                :ripple="false"
                color="error"
                :block="$vuetify.breakpoint.mobile"
                subtitle="Interested in cooperation?"
                title="Request a free consultation"
                :arrow="$vuetify.breakpoint.width > 360"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
/* Local modules */
import Hero from '@/components/Hero.vue';
import VBtnArrow from '@/components/VBtnArrow.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    VBtnArrow,
  },
  data() {
    return {
      sectionObserver: null,
      serviceList: [
        {
          img: 'img/consultancy.svg',
          title: 'Consultancy',
          content: 'We provide you with the advice and resources to streamline and <strong>achieve your goals</strong> by examining your end-to-end business needs. This includes offering valuable insights that uncover <strong>unique value propositions</strong> and consumer engagements.',
        },
        {
          img: 'img/management.svg',
          title: 'Management',
          content: 'We will help transform the way your fintech business operates by aligning your <strong>business strategies</strong> with your workforce goals, processes, technology, and optimisation. We deliver <strong>actionable solutions</strong> to real business challenges through our bespoke management services.',
        },
        {
          img: 'img/advisory.svg',
          title: 'Advisory',
          content: 'We are more than happy to extend our experience and fintech industry knowledge in an advisory role from <strong>the early startup</strong> to <strong>the established veteran</strong>. We will walk beside you as a contributing voice of reason using data-driven modeling and analytics to provide information for your decision-making.',
        },
        {
          img: 'img/support.svg',
          title: 'Support',
          content: 'We go beyond a simple phone call and take an active position in helping you foster more <strong>rewarding customer relationships</strong>, <strong>business processes</strong>, and <strong>internal culture</strong>. Fintech is a relatively new industry where many unknown roadblocks require creative and experienced solutions. Lean on Devonshire Associates to offer a pathway forward.',
        },
        {
          img: 'img/marketing.svg',
          title: 'Marketing',
          content: 'Our fintech consultants will <strong>unleash the power of your brand</strong> across multiple digital and traditional marketing channels to engage with more lead conversions and to spread your messaging. We have the skills you need to elevate your <strong>brand visibility</strong>.',
        },
        {
          img: 'img/bespoke-solutions.svg',
          title: 'Bespoke solutions',
          content: 'No two companies are ever going to be the same. Each business has <strong>unique competitive advantages</strong>, value propositions, and stories that require a comprehensive understanding and engaged consideration. That is why we offer <strong>tailormade consultations</strong> for all our clients.',
        },
      ],
      valueList: [
        {
          img: 'img/group.svg',
          title: 'Professionalism',
          content: 'We provide reliable, consistent, and honest advice using appropriate and appreciated communication methods.',
        },
        {
          img: 'img/group.svg',
          title: 'Team-play',
          content: 'We work with all team members to overcome any challenge you face and listen to all contributors for insight.',
        },
        {
          img: 'img/group.svg',
          title: 'Courage',
          content: 'We boldly step forward analyzing risk and generating solid results for clients because of our courage in this groundbreaking industry.',
        },
        {
          img: 'img/group.svg',
          title: 'Expertise',
          content: 'Our skill and knowledge to make businesses flourish in the fintech sector set us apart from general consultants.',
        },
      ],
    };
  },
  mounted() {
    this.observeSections();
  },
  methods: {
    debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    getImgUrl(pic) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`../assets/${pic}`);
    },
    observeSections() {
      try {
        this.sectionObserver.disconnect();
        // eslint-disable-next-line no-empty
      } catch (error) {
      }

      const options = {
        rootMargin: '0px 0px',
        threshold: 0.4,
      };
      this.sectionObserver = new IntersectionObserver(this.debounce(this.sectionObserverHandler), options);

      // Observe each section
      const sections = document.querySelectorAll('.section-hash');
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          // Push sectionId to router here
          // history.replaceState({}, '', `#${sectionId}`);

          this.$router.replace({
            name: this.$route.name,
            hash: `#${sectionId}`,
            params: {
              scroll: false,
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.v-card.service-card {
}
.line {
  width: 1px;
  background-color: #dd2215;
  height: 100%;
  float: left;
}

.section {
  padding-bottom: 48px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-bottom: 96px;
  }
}

.section-top {
  background-color: #e8e9eb;
  padding-top: 48px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-top: 96px;
  }
}

.section-about-us {
  padding-bottom: 36px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-bottom: 96px;
  }
}

.section-our-values {
  padding-bottom: 24px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-bottom: 96px;
    background-color: #e8e9eb
  }
}

.container-neg {
  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-left: 64px;
    padding-right: 64px;
    margin-top: -120px;
  }
}
.card-xl-px56 {
  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-left: 56px;
    padding-right: 56px;
  }
}

.card-service {
  padding-top: 40px;
  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-top: 56px;
  }
}
.container-values {
  @media #{map-get($display-breakpoints, 'xl-only')}{
    margin-bottom: -400px;
    padding-left: 64px;
    padding-right: 64px;
  }
}
.challenge-section {
  background-color: #231a13;
  padding-top: 36px;
  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-top: 376px;
    padding-bottom: 96px;
  }
}
.vertical-line {
  position: fixed;
  display: inline-block;
  border-left: 1px solid #fff;
  opacity: 0.1;
  z-index: 1;
  height: 100%;
}
</style>
