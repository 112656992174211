<template>
  <v-app>
    <app-nav-bar :is-home="false"/>
    <v-main>
        <slot/>
    </v-main>
  </v-app>
</template>

<script>
/* Local modules */
import AppNavBar from '@/components/app/AppNavBar.vue';

export default {
  name: 'Blank',
  components: {
    AppNavBar,
  },
};
</script>
<style>
</style>
