import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1400,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#007bff',
        secondary: '#231a13',
        accent: '#e8e9eb',
        error: '#dd2215',
        info: '#2196f3',
        success: '#4caf50',
        warning: '#ffc107',
      },
    },
  },
});
