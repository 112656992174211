<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
/* Local modules */
import DefaultLayout from '@/layout/Default.vue';
import BlankLayout from '@/layout/Blank.vue';

export default {
  name: 'App',
  components: {
    DefaultLayout,
    BlankLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`;
    },
  },
};
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

@media #{map-get($display-breakpoints, 'xl-only')}{
  .container-wrapper {
    //margin-left: 125px;
    //margin-right: 125px;
    padding-left: 120px;
    padding-right: 120px;
    //margin-left:unset;
    //margin-right:unset;
  }
}

.link {
  text-decoration: none;
  color: inherit !important;
}
</style>
