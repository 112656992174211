<template>
  <v-app>
    <app-nav-bar/>
    <v-main>
        <slot/>
    </v-main>
    <app-footer/>
  </v-app>
</template>

<script>
/* Local modules */
import AppNavBar from '@/components/app/AppNavBar.vue';
import AppFooter from '@/components/app/AppFooter.vue';

export default {
  name: 'Default',
  components: {
    AppNavBar,
    AppFooter,
  },
};
</script>
