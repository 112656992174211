<template>
  <v-footer
    app
    absolute
  >
    <v-container
      class="container-wrapper py-0">
      <v-row no-gutters class="footer-text">
        <v-col
          cols="12"
          xl="3"
        >
          <v-img
            class="shrink"
            contain
            src="@/assets/img/logo/logo_black.svg"
            transition="scale-transition"
            height="102"
            width="144"
          />
        </v-col>
        <v-col
          cols="12"
          xl="3"
          offset-xl="3"
          class="mt-12 mt-xl-0 pl-0"
        >
          <div style="border-left:1px solid red;" class="ml-n4 ml-md-0">
            <div class="pl-6 text-body-2">
              Chiswick Gate<br/>
              598-608 Chiswick High Rd<br/>
              London, W4 5RT<br/>
              United Kingdom
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          class="mt-6 mt-xl-0 pl-0"
        >
          <div style="border-left:1px solid red;" class="ml-n4 ml-md-0">
            <div class="pl-6 text-body-2">
              <a
                class="link"
                href="mailto:info@devonshireassociates.uk"
              >
                e: info@devonshireassociates.uk
              </a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.footer-subtitle {
  margin-top: 24px;
}
.footer-text {
  font-size: 14px;
  line-height: 1.5;
}
footer {
  padding-top: 48px;
  padding-bottom: 48px;

  @media #{map-get($display-breakpoints, 'xl-only')}{
    padding-top: 96px;
    padding-bottom: 96px;
  }
}
</style>
